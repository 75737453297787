
import { computed, defineComponent } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { CheckInRecordsStatusVM, UserPromoteRcoinRewardVM, WalletTypeEnum } from '@/view-models/promote.vm';
import { promoteQuery } from '@/apollo/queries';
import { commafy } from '@/utils/formatter';
import { RouteRecord } from '@/router';
import { useActivitySetting } from '@/composable/setting';

export default defineComponent({
  setup() {
    const { result } = useQuery<{ checkInRecordStatus: CheckInRecordsStatusVM }>(promoteQuery.getCheckInRecordStatus);
    const checkInRecordStatus = computed(() => result.value?.checkInRecordStatus ?? null);

    const promoteStatusDashboard = computed(
      () =>
        useQuery<{ promoteStatusDashboard: any }>(promoteQuery.getPromoteStatusDashboard, () => ({
          param: {},
        })).result.value?.promoteStatusDashboard ?? null,
    );

    const userWalletDashboardAll = computed(
      () =>
        useQuery<{ userWalletDashboard: any }>(promoteQuery.getUserWalletDashboard, () => ({
          param: {
            walletTypeId: WalletTypeEnum.all,
          },
        })).result.value?.userWalletDashboard ?? null,
    );

    const isAbleToReceive = computed(
      () =>
        useQuery<{ userPromoteRcoinRewards: UserPromoteRcoinRewardVM[] }>(promoteQuery.getUserPromoteRcoinRewards)
          .result.value?.userPromoteRcoinRewards ?? false,
    );

    const { dailyCheckInActivitySetting } = useActivitySetting();
    const showDailyCheckTip = computed(
      () =>
        dailyCheckInActivitySetting.value?.currentEnabled &&
        checkInRecordStatus &&
        !checkInRecordStatus?.value?.isTodayCheckIn,
    );

    return {
      RouteRecord,
      checkInRecordStatus,
      promoteStatusDashboard,
      userWalletDashboardAll,
      isAbleToReceive,
      showDailyCheckTip,

      commafy,
    };
  },
});
