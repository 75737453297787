import { computed, ref, watch } from 'vue';
import { Toast } from 'vant'

import { KOLSvc } from '@/services';
import type { BaseResponse, PageResponse, MockResponse } from '@/services/dto/base.dto'
import type { UserItem, UserProjectItem, UserPromotionItem, UserRewardItem, UserRewardDashboardItem, UserRewardBindingItem } from '@/services/dto/kol.dto'
import type { AxiosResponse } from 'axios'

export const isMock = ref(false);
export const setIsMock = (bool: boolean | null = null) => {
  if (bool === null) return isMock.value = !isMock.value;
  return isMock.value = bool;
};

const handleSvcError = (error) => {
  console.error(error);
  Toast({ type: 'fail', message: '伺服器忙線中 ...'});
  return;
}

const user = ref<null | BaseResponse<UserItem>>();
const userProject = ref<null | PageResponse<UserProjectItem>>();
const userPromotion = ref<null | PageResponse<UserPromotionItem>>();
const userReward = ref<null | PageResponse<UserRewardItem>>();
const userRewardDashboard = ref<null | BaseResponse<UserRewardDashboardItem>>();
const userRewardBinding = ref<null | PageResponse<UserRewardBindingItem>>();

const isTaiwanUser = computed(() => user.value?.item?.callingCode === '886')
const isSingaporeUser = computed(() => user.value?.item?.callingCode === '65')

const initUser = async () => {
  try {
    const response = await KOLSvc.getUser()
    if (response) {
      user.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const initUserProject = async () => {
  try {
    const response = await KOLSvc.getUserProject()
    if (response) {
      userProject.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const initUserPromotion = async () => {
  try {
    const response = await KOLSvc.getUserPromotion()
    if (response) {
      userPromotion.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const initUserReward = async () => {
  try {
    const response = await KOLSvc.getUserReward()
    if (response) {
      userReward.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const initUserRewardDashboard = async () => {
  try {
    const response = await KOLSvc.getUserRewardDashboard()
    if (response) {
      userRewardDashboard.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const initUserRewardBinding = async () => {
  try {
    const response = await KOLSvc.getUserRewardBinding()
    if (response) {
      userRewardBinding.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const resetUserProject = async (page) => {
  try {
    const response = await KOLSvc.getUserProject(page)
    if (response) {
      userProject.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const resetUserPromotion = async (page) => {
  try {
    const response = await KOLSvc.getUserPromotion(page)
    if (response) {
      userPromotion.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const resetUserReward = async (page, dateStart, dateEnd) => {
  try {
    const response = await KOLSvc.getUserReward(page, dateStart, dateEnd)
    if (response) {
      userReward.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const resetUserRewardBinding = async (page) => {
  try {
    const response = await KOLSvc.getUserRewardBinding(page)
    if (response) {
      userRewardBinding.value = response.data
    }
  } catch (error) {
    handleSvcError(error)
  }
}

const getAllKOLSvc = async () => {
  // HACK: Promise.all() => call multiple api in the same time.
  // REF: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all
  const promiseArray: [
    Promise<AxiosResponse<BaseResponse<UserItem>> | MockResponse<BaseResponse<UserItem>>>,
    Promise<AxiosResponse<PageResponse<UserProjectItem>> | MockResponse<PageResponse<UserProjectItem>>>,
    Promise<AxiosResponse<PageResponse<UserPromotionItem>> | MockResponse<PageResponse<UserPromotionItem>>>,
    Promise<AxiosResponse<PageResponse<UserRewardItem>> | MockResponse<PageResponse<UserRewardItem>>>,
    Promise<AxiosResponse<BaseResponse<UserRewardDashboardItem>> | MockResponse<BaseResponse<UserRewardDashboardItem>>>,
    Promise<AxiosResponse<PageResponse<UserRewardBindingItem>> | MockResponse<PageResponse<UserRewardBindingItem>>>,
  ] = [
    KOLSvc.getUser(),
    KOLSvc.getUserProject(),
    KOLSvc.getUserPromotion(),
    KOLSvc.getUserReward(),
    KOLSvc.getUserRewardDashboard(),
    KOLSvc.getUserRewardBinding(),
  ];
  try {
    Toast.loading({ message: '載入中 ...'})
    const promiseAllResponse = await Promise.all(promiseArray);
    [user, userProject, userPromotion, userReward, userRewardDashboard, userRewardBinding].forEach((ref, index) => {
      if (promiseAllResponse[index]) {
        ref.value = promiseAllResponse[index].data;
      }
    });
  } catch (error) {
    handleSvcError(error);
    return;
  } finally {
    Toast.clear();
  }
}
watch(isMock, async () => await getAllKOLSvc());


// main
export const useKOLSvc =  () => {
  return {
    user,
    isTaiwanUser,
    isSingaporeUser,
    initUser,

    userProject,
    initUserProject,
    resetUserProject,

    userPromotion,
    initUserPromotion,
    resetUserPromotion,

    userReward,
    initUserReward,
    resetUserReward,

    userRewardDashboard,
    initUserRewardDashboard,

    userRewardBinding,
    initUserRewardBinding,
    resetUserRewardBinding,
  }
}
