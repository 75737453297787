import { computed, ref } from 'vue';
import router, { RouteRecord, RouteRecordKOL } from '@/router';

// HACK: Use pathname to decide some page state. If change pathname, need to change here too.
const pathname = ref(window.location.pathname);
const isKOLPage = ref(pathname.value.includes('/kol'));
const isKOLSignUpPage = ref(pathname.value.includes('/kol') && pathname.value.includes('/sign-up'));

const updatePathname = () => {
  pathname.value = window.location.pathname;
  isKOLPage.value = pathname.value.includes('/kol');
  isKOLSignUpPage.value = pathname.value.includes('/kol') && pathname.value.includes('/sign-up');
  // console.log('update pathname', pathname.value);
  // console.log('update pathname isKOLPage: ', isKOLPage.value);
  // console.log('update pathname isKOLSignUpPage: ', isKOLSignUpPage.value);
};

export const usePageParameter = () => {
  const label = computed(() => router.currentRoute.value?.meta?.label);

  const isDetailsPage = computed(() => {
    if (isKOLPage.value) {
      return router.currentRoute.value.path.split('/').length >= 4;
    } else {
      return router.currentRoute.value.path.split('/').length >= 3;
    }
  });

  const isLandingPage = computed(() => router.currentRoute.value.name === RouteRecord.LandingPage.name);

  const isKOLLandingPage = computed(() => router.currentRoute.value.name === RouteRecordKOL.LandingPage.name);

  return { label, isLandingPage, isDetailsPage, isKOLPage, isKOLSignUpPage, isKOLLandingPage, updatePathname };
};
