import { ref, reactive, computed, toRefs, watchEffect } from 'vue';
import { Toast } from 'vant';
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

import router, { RouteRecord, RouteRecordKOL } from '@/router';
import { logoutWithGoogle, getGoogleBasicProfile, BasicProfileVM } from '@/plugins/google-api';
import {
  IdTokenVM,
  ApplyFormVM,
  KOLApplyFormVM,
  TokenTypeEnum,
  UserVM,
  KOLUserVM,
} from '@/view-models/auth.vm';
import { authMutation } from '@/apollo/mutations';
import { $apollo } from '@/plugins/vue-apollo';
import { authQuery } from '@/apollo/queries';
import { KOLSvc } from '@/services';
import type { BaseResponse } from '@/services/dto/base.dto'
import type { GoogleLoginItem, GoogleUserPayload } from '@/services/dto/kol.dto'
import { UserProjectItem } from '@/services/dto/kol.dto';
import { i18n } from '@/plugins/vue-i18n'

import useForm from './useForm';
import { usePageParameter } from './usePageParameter';
import { useKOLSvc } from '@/composable/services/useKolSvc';
import useProjectStorage from './useProjectStorage';
import useMockGoogleLogin from './useMockGoogleLogin'

export const { googleIdToken, projectRpt, projectT, projectMt, projectUtilsStorage } = useProjectStorage();
const { isMockGoogleLogin } = useMockGoogleLogin()

let isKOLPage;
// BUG: `usePageParameter` Reference error can't access by Initialization => use setTimeout to fix this bug
setTimeout(() => {
  const { isKOLPage: isKOLPageRaw } = usePageParameter();
  isKOLPage = isKOLPageRaw;
}, 1);
const { promotionForm, KOLForm, generateKOLForm } = useForm();

interface StateType {
  googleIdToken: string;
  token: string;
  tokenType: string;
  googleProfile: BasicProfileVM | null;
  user: UserVM | KOLUserVM | null; // maybe `delivery user` or `KOL user`
  applyForm: ApplyFormVM;
  KOLApplyForm: KOLApplyFormVM;
}

const state: StateType = reactive({
  googleIdToken: googleIdToken.value,
  token: '',
  tokenType: '',
  googleProfile: null,
  user: null,
  applyForm: promotionForm.applyForm,
  KOLApplyForm: KOLForm.applyForm,
});

//
// -------------- Promotion(original) ---------------
//

export const isLoggedIn = computed(() => state.tokenType === TokenTypeEnum.loginToken && !!state.token);
export const isRegisterToken = computed(() => state.tokenType === TokenTypeEnum.registerToken);
export const createTokenByGoogleIdToken = async () => {
  try {
    if (!state.googleIdToken) {
      return false;
    }

    const { data } = await $apollo.mutate<{ createTokenByGoogleIdToken: IdTokenVM }>({
      mutation: authMutation.createTokenByGoogleIdToken,
      variables: {
        idToken: state.googleIdToken,
      },
    });

    if (data?.createTokenByGoogleIdToken) {
      const { token, type, applyForm } = data?.createTokenByGoogleIdToken;
      state.token = token;
      state.tokenType = type;
      state.applyForm = applyForm;
    }

    return true;
  } catch (err) {
    state.googleIdToken = '';
    return false;
  }
};

//
// ------------------ KOL --------------------
//

// check rpt (token) is expired
const isProjectRptValidating = (rpt: string) => {
  if (!rpt) return false;
  const decoded = jwtDecode<GoogleUserPayload>(rpt)
  if (dayjs().isBefore(decoded.exp)) {
    return true;
  }
  projectRpt.value = ''
  return false;
}

const hasKOLProject = (projects: UserProjectItem[] | undefined) => projects?.some(project => project.projectId.toUpperCase() === 'KOL');

const googleLoginResponse = ref<BaseResponse<GoogleLoginItem> | null>(null);

let isCallingGoogleLogin = false
export const resetGoogleLoginResponse = async () => {
  if (isMockGoogleLogin.value) {
    if (!state.googleProfile) return
    state.googleProfile.id = googleIdToken.value
  } else if (!state.googleProfile) {
    googleLoginResponse.value = null;
    return;
  }

  try {
    isCallingGoogleLogin = true
    const response = await KOLSvc.postGoogleLogin({ googleUserId: state.googleProfile.id });
    // console.log('googleUserId: ', state.googleProfile.id, response)
    if (response.data.success) {
      googleLoginResponse.value = response.data;
      if (hasKOLProject(googleLoginResponse.value?.item?.projects)) {
        projectRpt.value = response.data.item?.token || ''
      } else {
        projectRpt.value = ''
      }
    } else {
      let message = response.data.resultMessage || '發生未知的錯誤，請聯繫客服人員'
      Toast({ type: 'fail', message })
      googleLoginResponse.value = null;
    }
  } catch (error) {
    console.error(error);
    googleLoginResponse.value = null;
  } finally {
    // console.log('reset google done', projectRpt.value, googleLoginResponse.value)
    isCallingGoogleLogin = true
  }
};

const asyncGoogleLoginResponse = async () => {
  if (!googleLoginResponse.value) {
    if (!state.googleProfile) {
      googleLoginResponse.value = null;
      state.googleProfile = await getGoogleBasicProfile(googleIdToken.value);
      const googleUserId = state?.googleProfile?.id ?? ''
      state.KOLApplyForm.googleUserId = googleUserId
    }
    if (isCallingGoogleLogin) return googleLoginResponse.value;
    await resetGoogleLoginResponse();
  }
  return googleLoginResponse.value
}

export const isKOLLoggedIn = computed(() => !!googleIdToken.value);
export const isNeedRegisterKOL = ref(false)
watchEffect(async () => {
  if (!isKOLLoggedIn.value) return isNeedRegisterKOL.value = false;

  try {
    const response = await asyncGoogleLoginResponse()
    if (!response) {
      throw new Error('no response')
    };
    if (!response.item) return isNeedRegisterKOL.value = true;
    const KOLProject = response.item?.projects.find((e) => e.projectId.toUpperCase() === 'KOL')
    return isNeedRegisterKOL.value = !KOLProject?.enabled
  } catch (error) {
    console.error(error)
    // return isNeedRegisterKOL.value = true;
    return;
  }
})

export const createTokenByGoogleIdWithReApi = async () => {
  if (isProjectRptValidating(projectRpt.value)) {
    return true;
  }

  if (!googleLoginResponse.value) {
    if (!state.googleProfile) return false;
    try {
      await resetGoogleLoginResponse();
      if (projectRpt.value) return true;
      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  const token = googleLoginResponse.value?.item?.token || ''
  // console.log('res', googleLoginResponse.value)
  // console.log('projectRpt', token)
  if (token) {
      if (hasKOLProject(googleLoginResponse.value.item?.projects)) {
        projectRpt.value = token;
        return true;
      }
      return false;
  } else {
    return false;
  }
};

//
// ------------------ Utils --------------------
//

export default () => {
  const { user: KOLUser, initUser: initKOLUser } = useKOLSvc()

  const getProfileByGraphql = async () => {
    try {
      const {
        data: { user },
      } = await $apollo.query<{ user: UserVM }>({
        query: authQuery.getUser,
        fetchPolicy: 'network-only',
      });
      state.user = user;
    } catch (err) {
      console.log(err);
      if (!isLoggedIn.value) {
        Toast({ type: 'fail', message: i18n.global.t('login_failed_msg') });
        return;
      }
    }
  };

  const getProfileByReApi = async () => {
    if (!state.googleProfile) {
      Toast({ type: 'fail', message: i18n.global.t('google_user_not_found_msg') });
      return;
    }
    KOLApplyForm.value.googleUserId = state.googleProfile.id
    try {
      if (!projectRpt.value) await asyncGoogleLoginResponse()
      if (!projectRpt.value) return isNeedRegisterKOL.value = true;

      if (KOLUser.value?.item?.googleUserId) {
        if (KOLUser.value?.item?.googleUserId !== state.googleProfile.id || !KOLUser.value) await initKOLUser()
      } else {
        if (!KOLUser.value) await initKOLUser()
      }

      if (!googleLoginResponse.value) return;
      KOLApplyForm.value.reMemberId = googleLoginResponse.value?.item?.userPayload.rmi || '';
      const token = googleLoginResponse.value?.item?.token || '';
      if (hasKOLProject(googleLoginResponse.value.item?.projects)) {
        projectRpt.value = token;
        return true;
      } else {
        projectRpt.value = '';
        return true;
      }
    } catch (err) {
      console.error(err);
      if (!isKOLLoggedIn.value) {
        Toast({ type: 'fail', message: i18n.global.t('login_failed_msg') });
        return;
      }
    }
  };

  const getProfile = async () => {
    if (!googleIdToken.value) {
      Toast({ type: 'fail', message: i18n.global.t('login_google_first_msg'), duration: 3000 });
      // redirect in route guard
      return;
    }
    if (!isMockGoogleLogin.value) {
      state.googleProfile = await getGoogleBasicProfile(googleIdToken.value);
    }
    if (isKOLPage.value) {
      await getProfileByReApi();
    } else {
      await getProfileByGraphql();
    }
  };

  const logout = async () => {
    await logoutWithGoogle();
    projectUtilsStorage.value.mobile = '';
    projectUtilsStorage.value.callingCode = ''
    projectUtilsStorage.value.otpId = '';
    projectUtilsStorage.value.mt = '';
    // projectUtilsStorage.value.hasShowKOLRules = false;

    googleLoginResponse.value = null;
    googleProfile.value = null;
    KOLUser.value = null;

    projectMt.value = '';
    projectRpt.value = '';

    state.tokenType = '';
    state.token = '';
    state.KOLApplyForm = generateKOLForm().applyForm;

    if (isKOLPage.value) {
      await router.push({ name: RouteRecordKOL.Login.name });
    } else {
      await router.push({ name: RouteRecord.Login.name });
    }
  };

  return {
    getProfile,
    logout,
  }
}

// main ----
export const { token, tokenType, applyForm, user, googleProfile } = toRefs(state);
export const { KOLApplyForm } = toRefs(state);
