import { Notify } from 'vant';
import jwtDecode from 'jwt-decode';

import { googleIdToken } from '@/composable/auth';

const GAPI_ID = 'gapi-id';

export function installGapiSDK(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (document.getElementById(GAPI_ID)) {
      initClient();
      return;
    }
    // console.log('load google sdk');
    const newScript = document.createElement('script');
    newScript.id = GAPI_ID;
    newScript.src = 'https://accounts.google.com/gsi/client';
    newScript.onload = () => {
      initClient();
    };
    newScript.onerror = (err) => {
      console.log(err);
      Notify({
        type: 'danger',
        message: `gsi/client 載入失敗`,
        duration: 0,
      });
    };
    document.head.appendChild(newScript);

    async function initClient() {
      try {
        window.google?.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID as string,
          auto_select: false,
          ux_mode: 'popup',
          callback(res) {
            googleIdToken.value = res.credential as string;
          },
        });

        resolve();
      } catch (err) {
        console.error(err);
        let message = 'Unknown Error';
        if (err instanceof Error) message = err.message || err.toString();
        Notify({
          type: 'danger',
          message,
          duration: 0,
        });
        reject(err);
      }
    }
  });
}

export function logoutWithGoogle(): void {
  window.google?.accounts.id.revoke();
  window.google?.accounts.id.disableAutoSelect();
  googleIdToken.value = '';
}

export interface BasicProfileVM {
  id: string;
  name: string;
  imageUrl: string;
  email: string;
}

export function getGoogleBasicProfile(token: string) {
  const obj: {
    sub: string;
    name: string;
    picture: string;
    email: string;
  } = jwtDecode(token);
  console.log('jwtDecode', obj);

  return {
    id: obj.sub,
    name: obj.name,
    imageUrl: obj.picture,
    email: obj.email,
  };
}

/**
  ref. https://developers.google.com/identity/sign-in/web/backend-auth

  [GET] https://oauth2.googleapis.com/tokeninfo?id_token={id_token}
  response: {
    "iss": "accounts.google.com",
    "azp": "730212156125-m8a7ue3c6nd13q3ddfk70gakh6kb9dad.apps.googleusercontent.com",
    "aud": "730212156125-m8a7ue3c6nd13q3ddfk70gakh6kb9dad.apps.googleusercontent.com",
    "sub": "104637371070697760470",
    "hd": "myre.life",
    "email": "david.jian@myre.life",
    "email_verified": "true",
    "at_hash": "zkDLPqVxak1hKmLbE1TdRQ",
    "name": "David Jian",
    "picture": "https://lh3.googleusercontent.com/a-/AOh14GiZGqNqVdeQXtYb34dClFhenQjtkh8DY5f73Gqh=s96-c",
    "given_name": "David",
    "family_name": "Jian",
    "locale": "zh-TW",
    "iat": "1626082197",
    "exp": "1626085797",
    "jti": "294a1e45f59009edc8cc15b9cfa1b54a2d376ac5",
    "alg": "RS256",
    "kid": "1bf8a84d3ecd77e9f2ad5f06ffd260701dd06d90",
    "typ": "JWT"
  }

  [GET] https://oauth2.googleapis.com/tokeninfo?access_token={access_token}
  response: {
    "azp": "730212156125-m8a7ue3c6nd13q3ddfk70gakh6kb9dad.apps.googleusercontent.com",
    "aud": "730212156125-m8a7ue3c6nd13q3ddfk70gakh6kb9dad.apps.googleusercontent.com",
    "sub": "104637371070697760470",
    "scope": "openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.metadata.readonly",
    "exp": "1626085797",
    "expires_in": "3002",
    "email": "david.jian@myre.life",
    "email_verified": "true",
    "access_type": "offline"
  }
 */
