// !mock data fro development.

export const getUser = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    item: {
      id: 'df500993-b46d-4a01-9008-16fb95d364fe',
      googleUserId: '110198955668882071121',
      reMemberId: null,
      name: null,
      gender: null,
      email: null,
      callingCode: null,
      mobile: null,
      city: null,
      region: null,
      address: null,
      dateBirth: null,
      promoteCode: null,
      projects: [
        {
          projectId: 'kol',
          dateStart: '2022-09-29T09:57:26.000Z',
          dateEnd: '2222-09-29T09:57:26.000Z',
          enabled: true,
          dateUpdated: '2022-09-29T09:57:28.000Z',
          dateCreated: '2022-10-05T01:46:00.000Z',
        },
        {
          projectId: 'Vim 宗教戰爭',
          dateStart: '2022-09-29T09:57:26.000Z',
          dateEnd: '2222-09-29T09:57:26.000Z',
          enabled: true,
          dateUpdated: '2022-09-29T09:57:28.000Z',
          dateCreated: '2022-10-05T01:46:00.000Z',
        },
        {
          projectId: 'Vue 宗教戰爭',
          dateStart: '2022-09-29T09:57:26.000Z',
          dateEnd: '2222-09-29T09:57:26.000Z',
          enabled: true,
          dateUpdated: '2022-09-29T09:57:28.000Z',
          dateCreated: '2022-10-05T01:46:00.000Z',
        },
      ],
    },
  },
};

export const getUserReward = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    items: [
      {
        id: '0c2ee5f6-1ad7-4a94-911e-bed57d238c32',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        projectRewardCode: 'referred-person-consumes',
        refId: '897e648e-29de-4c50-8d24-26c56fa6bb1f',
        rcoin: 1,
        isCanceled: false,
        cancelReason: null,
        dateCanceled: null,
        isReceived: false,
        dateReceived: null,
        dateCreated: '2022-10-05T04:04:09.000Z',
        dateUpdated: '2022-10-05T03:46:19.000Z',
      },
      {
        id: '0c2ee5f6-1ad7-4a94-911e-bed57d238c32',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        projectRewardCode: 'referred-person-consumes',
        refId: '897e648e-29de-4c50-8d24-26c56fa6bb1f',
        rcoin: 1,
        isCanceled: false,
        cancelReason: null,
        dateCanceled: null,
        isReceived: false,
        dateReceived: null,
        dateCreated: '2022-10-05T04:04:09.000Z',
        dateUpdated: '2022-10-05T03:46:19.000Z',
      },
      {
        id: '0c2ee5f6-1ad7-4a94-911e-bed57d238c32',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        projectRewardCode: 'referred-person-consumes',
        refId: '897e648e-29de-4c50-8d24-26c56fa6bb1f',
        rcoin: 1,
        isCanceled: true,
        cancelReason: null,
        dateCanceled: null,
        isReceived: false,
        dateReceived: null,
        dateCreated: '2022-10-05T04:04:09.000Z',
        dateUpdated: '2022-10-05T03:46:19.000Z',
      },
      {
        id: '0c2ee5f6-1ad7-4a94-911e-bed57d238c32',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        projectRewardCode: 'referred-person-consumes',
        refId: '897e648e-29de-4c50-8d24-26c56fa6bb1f',
        rcoin: 1,
        isCanceled: false,
        cancelReason: null,
        dateCanceled: null,
        isReceived: true,
        dateReceived: null,
        dateCreated: '2022-10-05T04:04:09.000Z',
        dateUpdated: '2022-10-05T03:46:19.000Z',
      },
      {
        id: '0c2ee5f6-1ad7-4a94-911e-bed57d238c32',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        projectRewardCode: 'referred-person-consumes',
        refId: '897e648e-29de-4c50-8d24-26c56fa6bb1f',
        rcoin: 1,
        isCanceled: false,
        cancelReason: null,
        dateCanceled: null,
        isReceived: true,
        dateReceived: null,
        dateCreated: '2022-10-05T04:04:09.000Z',
        dateUpdated: '2022-10-05T03:46:19.000Z',
      },
    ],
    page: {
      dataAmount: 5,
      pageAmount: 1,
      index: 1,
      size: 10,
    },
  },
};

export const getUserRewardBinding = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    items: [
      {
        id: '7ffcef9e-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '041def60-4a4d-11eb-9234-3be6d33aa70c',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffd081c-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '126187b0-9263-11e9-955f-6d942e51e6e3',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcf0cc-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '1c59c910-3748-11e9-9375-6f303b9a89cb',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcf49b-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '1cf27a90-4a59-11eb-9234-3be6d33aa70c',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcf2cb-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '1fd38120-3735-11e9-860e-27453681c656',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcff7d-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '37a95010-39a9-11e9-90d9-63c3894be2b9',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcfc00-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '38468a10-4a78-11eb-8aff-79ffc07b0258',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffcf831-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '401d6440-4a71-11eb-8aff-79ffc07b0258',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffd005e-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '41bcf8f0-bcca-11e9-8901-5d6c2ac45298',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
      {
        id: '7ffd013a-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        projectId: 'kol',
        reMemberId: '5ea0c4d0-a3fc-11e7-9587-d9bf41c52ff4',
        reStoreId: null,
        childUserId: null,
        dateStart: '2022-10-05T02:58:26.000Z',
        dateEnd: '2200-12-31T15:59:59.000Z',
        enabled: true,
        dateUpdated: '2022-10-05T02:57:50.000Z',
        dateCreated: '2022-10-05T02:58:41.000Z',
      },
    ],
    page: {
      dataAmount: 28,
      pageAmount: 3,
      index: 1,
      size: 10,
    },
  },
};

export const getUserRewardDashboard = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    item: {
      currencyCode: 'TWD',
      totalRcoin: 6.58,
      receivedRcoin: 6,
      waitingEffectedRcoin: 0.58,
    },
  },
};

export const getUserProject = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    items: [
      {
        projectId: 'kol',
        dateStart: '2022-09-29T09:57:26.000Z',
        dateEnd: '2222-09-29T09:57:26.000Z',
        enabled: true,
        dateUpdated: '2022-09-29T09:57:28.000Z',
        dateCreated: '2022-10-05T01:46:00.000Z',
      },
      {
        projectId: 'Vim 宗教戰爭',
        dateStart: '2022-09-29T09:57:26.000Z',
        dateEnd: '2222-09-29T09:57:26.000Z',
        enabled: true,
        dateUpdated: '2022-09-29T09:57:28.000Z',
        dateCreated: '2022-10-05T01:46:00.000Z',
      },
      {
        projectId: 'Vue 宗教戰爭',
        dateStart: '2022-09-29T09:57:26.000Z',
        dateEnd: '2222-09-29T09:57:26.000Z',
        enabled: true,
        dateUpdated: '2022-09-29T09:57:28.000Z',
        dateCreated: '2022-10-05T01:46:00.000Z',
      },
    ],
    page: {
      dataAmount: 4,
      pageAmount: 1,
      index: 1,
      size: 10,
    },
  },
};

export const getUserPromotion = {
  success: true,
  data: {
    success: true,
    resultMessage: '',
    resultException: '',
    resultCode: 200,
    items: [
      {
        id: '2e916142-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        reMemberId: '041def60-4a4d-11eb-9234-3be6d33aa70c',
        reStoreId: null,
        childUserId: null,
        dateCreated: '2022-10-05T02:55:34.000Z',
      },
      {
        id: '2e918eb6-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        reMemberId: '126187b0-9263-11e9-955f-6d942e51e6e3',
        reStoreId: null,
        childUserId: null,
        dateCreated: '2022-10-05T02:55:34.000Z',
      },
      {
        id: '2e9162ac-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        reMemberId: '1c59c910-3748-11e9-9375-6f303b9a89cb',
        reStoreId: null,
        childUserId: null,
        dateCreated: '2022-10-05T02:55:34.000Z',
      },
      {
        id: '2e916a2d-4459-11ed-b144-0242ac110004',
        userId: 'df500993-b46d-4a01-9008-16fb95d364fe',
        reMemberId: '1cf27a90-4a59-11eb-9234-3be6d33aa70c',
        reStoreId: null,
        childUserId: null,
        dateCreated: '2022-10-05T02:55:34.000Z',
      },
    ],
    page: {
      dataAmount: 4,
      pageAmount: 1,
      index: 1,
      size: 10,
    },
  },
};
