
import { computed, defineComponent, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

import { showSideBar, isLargeScreen, toggleSideBar } from '@/composable/sidebar';
import useAuth, { googleProfile, user } from '@/composable/auth';
import router, { RouteRecord } from '@/router';

const { logout } = useAuth();

export default defineComponent({
  setup() {
    const target = ref(null);

    const get = (value, defaultValue = '') => value ?? defaultValue;

    const menu = computed(() => {
      const routes = router.getRoutes();
      return routes.filter((route) => !!route.meta?.menu);
    });
    const activeKey = computed(() => menu.value.findIndex((route) => route.name === router.currentRoute.value.name));

    watch(router.currentRoute, () => {
      if (!isLargeScreen.value) {
        toggleSideBar(false);
      }
    });

    onClickOutside(target, () => {
      if (!isLargeScreen.value) {
        toggleSideBar(false);
      }
    });

    return {
      get,

      RouteRecord,
      googleProfile,
      user,
      target,
      activeKey,
      showSideBar,
      menu,

      logout,
    };
  },
});
