import gql from 'graphql-tag';

export const userPayloadFragment = gql`
  fragment userPayload on UserPayload {
    # i
    rmi
    # gi
    # t
    exp
    # iat
    # iss
  }
`;

export const userFragment = gql`
  fragment user on User {
    id
    reMemberId
    callingCode
    mobile
    email
    dateBirth
    name
    city
    region
    address
    promoteCode
    inviterPromoteCode
  }
`;

export const reMemberFragment = gql`
  fragment reMember on ReMember {
    id
    callingCode
    mobile
    name
    promotionUser {
      ...user
    }
  }
  ${userFragment}
`;

export const applyFormFragment = gql`
  fragment applyForm on ApplyForm {
    id
    reMemberId
    callingCode
    mobile
    dateBirth
    name
    gender
    email
    city
    region
    address
    promoteCode
    inviterPromoteCode
    status
    memo
    userId
    dateCreated
    dateUpdated
    smsOtpId
    isMobileVerified
    user {
      ...user
    }
    inviterPromoteReMember {
      ...reMember
    }
    certificateImageUrl
    isAccepted
    dateAccepted
    googleUserId
    reMember {
      ...reMember
    }
    ableToUpdateInviterPromotionCode
    denyReason
  }
  ${reMemberFragment}
  ${userFragment}
`;

export const getUser = gql`
  query getUser {
    user {
      ...user
    }
  }
  ${userFragment}
`;

export const checkInviterMemberExistByPromoteCode = gql`
  query checkInviterMemberExistByPromoteCode($param: CheckInviterMemberExistByPromoteCodeParameter!) {
    checkInviterMemberExistByPromoteCode(param: $param) {
      isExist
      reMember {
        ...reMember
      }
    }
  }
  ${reMemberFragment}
`;

export const checkUserExistByPromoteCode = gql`
  query checkUserExistByPromoteCode($promoteCode: String!) {
    checkUserExistByPromoteCode(promoteCode: $promoteCode) {
      isExist
      promotionUser {
        ...user
      }
    }
  }
  ${userFragment}
`;
