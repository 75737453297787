import LandingPage from '@/views/feature-kol/LandingPage.vue';
import Home from '@/views/feature-kol/BackStage/Home.vue';
import { i18n } from '@/plugins/vue-i18n';
import { KOLSvc } from '@/services';

function loadComponent(filePath: string, index = false) {
  return () => import(`../views/feature-kol/${filePath}${index ? '/index' : ''}.vue`);
}

const KOLPath = '/kol';

export const RouteRecordKOL = {
  Home: {
    name: 'KOLHome',
    path: `${KOLPath}/home`,
    component: Home,
    meta: {
      auth: true,
      get label() {
        return i18n.global.t('home_page_name')
      },
      KOLMenu: true,
    },
  },
  Recommend: {
    name: 'KOLRecommend',
    path: `${KOLPath}/recommend`,
    component: loadComponent('BackStage/Recommend'),
    meta: {
      get label() {
        return i18n.global.t('recommend_page_name')
      },
      auth: true,
      KOLMenu: true,
    },
  },
  Feedback: {
    name: 'Feedback',
    path: `${KOLPath}/feedback`,
    component: loadComponent('BackStage/Feedback'),
    meta: {
      get label() {
        return i18n.global.t('feedback_page_name')
      },
      auth: true,
      KOLMenu: true,
    },
  },
  User: {
    name: 'User',
    path: `${KOLPath}/user`,
    component: loadComponent('BackStage/User'),
    meta: {
      get label() {
        return i18n.global.t('profile_page_name')
      },
      auth: true,
      KOLMenu: true,
    },
  },
  Login: {
    name: 'KOLLogin',
    path: `${KOLPath}/login`,
    component: loadComponent('Login'),
    meta: {
      auth: false,
      label: i18n.global.t('login_page_name'),
      KOLMenu: false,
    },
  },
  SignUp: {
    name: 'KOLSignUp',
    path: `${KOLPath}/sign-up`,
    component: loadComponent('SignUp', true),
    meta: {
      auth: false,
      get label() {
        return i18n.global.t('kol_sign_in_page_name')
      },
      KOLMenu: false,
    },
  },
  SignUpForm: {
    name: 'KOLSignUpForm',
    path: 'form',
    component: loadComponent('SignUp/Form'),
    meta: {
      auth: false,
      KOLMenu: false,
    },
  },
  SignUpSms: {
    name: 'KOLSignUpSms',
    path: 'sms',
    component: loadComponent('SignUp/Sms'),
    meta: {
      get label() {
        return i18n.global.t('re_member_login_page_name')
      },
      auth: false,
      KOLMenu: false,
    },
  },
  SignUpDone: {
    name: 'KOLSignUpDone',
    path: 'done',
    component: loadComponent('SignUp/Done'),
  },
  LandingPageRedirect: {
    name: 'KOLLandingPageRedirect',
    path: KOLPath,
    component: LandingPage,
    async beforeEnter(to, from, next){
      const { data: ret } = await KOLSvc.getIpLocation()
      if(!ret.success) {
        return next({ name: RouteRecordKOL.LandingPage.name, params: { area: 'tw' } })
      }

      next({ name: RouteRecordKOL.LandingPage.name, params: { area: ret.location.ipLocation } });
    },
    meta: {
      auth: false,
      menu: false,
      KOLMenu: false,
    }
  },
  LandingPage: {
    name: 'KOLLandingPage',
    path: `${KOLPath}/:area`,
    component: LandingPage,
    meta: {
      get label() {
        return i18n.global.t('kol_landing_page_name')
      },
      auth: false,
      menu: false,
      KOLMenu: false,
    },
  },
};
