import axios from 'axios';
import axiosRetry from 'axios-retry';
import { Toast } from 'vant';
import getUserAgent from '@/utils/getUserAgent';
import 'dotenv/config';

const SECOND = 1000;
const RETRIES = 5;

// ref. https://github.com/axios/axios
const baseAxios = axios.create({
  timeout: 30000,
  headers: {
    aui: '0aa5bf20c69d1773c5d274a9cb451a40',
    ua: JSON.stringify(getUserAgent()),
    'Content-Type': 'application/json',
    // LANG: i18n.locale
  },
});

/**
 * axios retry
 * REF: https://github.com/softonic/axios-retry
 */
axiosRetry(baseAxios, {
  retries: RETRIES,
  retryDelay: (retryCount) => {
    if (retryCount > RETRIES - 1) {
      Toast({ type: 'fail', message: '伺服器忙線中 ...' });
      return 0;
    }
    return retryCount * 5 * SECOND;
  },
});

export default baseAxios;
