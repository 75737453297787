// import { App } from 'vue';
// import { ApolloProvider } from '@vue/apollo-option';

import { apolloClient } from '@/apollo/network-interfaces';

// const apolloProvider = new ApolloProvider({
//   defaultClient: apolloClient,
//   // ref. https://apollo.vuejs.org/guide/multiple-clients.html
//   // clients: {
//   //   'v1': apolloClient,
//   // }
// })

export const $apollo = apolloClient;

// export function installVueApollo(app: App<Element>) {
//   app.use(apolloProvider)
// }
