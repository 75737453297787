import { watch } from 'vue';
import { useToggle, useMediaQuery } from '@vueuse/core';

const isLargeScreen = useMediaQuery('(min-width: 1024px)');
const [showSideBar, toggle] = useToggle(isLargeScreen.value);

function toggleSideBar(bool?: boolean) {
  toggle(bool);
}

watch(isLargeScreen, (bool) => {
  console.log(bool);
  toggleSideBar(bool);
});

export { isLargeScreen, showSideBar, toggleSideBar };
