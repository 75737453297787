import gql from 'graphql-tag';
import { applyFormFragment, userPayloadFragment, userFragment, reMemberFragment } from '../queries/auth';

export const createTokenByGoogleIdToken = gql`
  mutation createTokenByGoogleIdToken($idToken: String!) {
    createTokenByGoogleIdToken(idToken: $idToken) {
      token
      type
      userPayload {
        ...userPayload
      }
      applyForm {
        ...applyForm
      }
      user {
        ...user
      }
    }
  }
  ${userFragment}
  ${applyFormFragment}
  ${userPayloadFragment}
`;

export const sendSmsOtpVerification = gql`
  mutation sendSmsOtpVerification($param: SendSmsOtpVerificationParameter!) {
    sendSmsOtpVerification(param: $param) {
      smsOtpId
      dateNextSend
    }
  }
`;

export const verifySmsOtpVerification = gql`
  mutation verifySmsOtpVerification($param: VerifySmsOtpVerificationParameter!) {
    verifySmsOtpVerification(param: $param) {
      applyForm {
        ...applyForm
      }
      token
      type
      userPayload {
        ...userPayload
      }
    }
  }
  ${applyFormFragment}
  ${userPayloadFragment}
`;

export const updateApplyForm = gql`
  mutation updateApplyForm($param: UpdateApplyFormParameter!) {
    updateApplyForm(param: $param) {
      ...applyForm
    }
  }
  ${applyFormFragment}
`;

export const confirmApplyForm = gql`
  mutation confirmApplyForm($id: String!) {
    confirmApplyForm(id: $id) {
      ...applyForm
    }
  }
  ${applyFormFragment}
`;
