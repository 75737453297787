
import { defineComponent, ref, computed } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

import { RouteRecord } from '@/router';
import { useDetectedInLineBrowser } from '@/composable/browser';

export default defineComponent({
  setup() {
    const year = computed(() => new Date().getFullYear());
    const target = ref(null);
    const targetIsVisible = ref(false);

    useDetectedInLineBrowser();

    useIntersectionObserver(
      target,
      ([entrie], observerElement) => {
        targetIsVisible.value = entrie.isIntersecting;
      },
      { threshold: 0 },
    );

    return {
      year,
      RouteRecord,
      target,
      targetIsVisible,
    };
  },
});
