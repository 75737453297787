import { onMounted } from 'vue';
import { Dialog } from 'vant';

const inLine = window.navigator?.userAgent?.includes('Line');
const inFB = window.navigator?.userAgent?.includes('FB');
const inWebView = window.navigator?.userAgent?.includes('; wv');

export function useDetectedInLineBrowser() {
  onMounted(() => {
    try {
      if (inLine) {
        document.querySelectorAll('a').forEach((el) => {
          el.target = '_blank';
          const urlObj = new URL(el.href);
          urlObj.search += `${urlObj.search.includes('?') ? '&' : '?'}openExternalBrowser=1`;
          el.href = urlObj.href;
        });
      }
    } catch (err) {}
  });
}

export function detectedInAppBrowser() {
  if (inFB || inLine || inWebView) {
    Dialog.alert({
      title: '提醒',
      message: `
        <div class="text-left leading-normal whitespace-normal">
          <div class="">請使用「預設(外部)瀏覽器」開啟，以確保功能正常。</div>
          <div class="my-2">
            <div class="font-bold text-lg">從 Line 開啟預設瀏覽器</div>
            <div>1. 點擊右上角紅圈處</div>
            <img src="${process.env.BASE_URL}line1.jpg" />
            <div>2. 選擇使用外部瀏覽器開啟</div>
            <img src="${process.env.BASE_URL}line2.jpg" />
          </div>
          <div class="my-2">
            <div class="font-bold text-lg">從 Facebook 開啟預設瀏覽器</div>
            <div>1. 點擊右下角紅圈處</div>
            <img src="${process.env.BASE_URL}fb1.jpg" />
            <div>2. 選擇以瀏覽器開啟</div>
            <img src="${process.env.BASE_URL}fb2.jpg" />
          </div>

        </div>
      `,
      allowHtml: true,
    });
  }
}
