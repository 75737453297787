import dayjs from 'dayjs';

export const formatDate = (str: string | null, hasTime: boolean = true) => {
  if (!str) {
    return '';
  }
  return dayjs(str).format(hasTime ? 'YYYY/MM/DD HH:mm' : 'YYYY/MM/DD');
};

export const formatDateForKOLSvc = (str: string) => {
  if (!str) return '';
  return dayjs(str).format('YYYY-MM-DD');
};

export const commafy = (num: string | number, maximumFractionDigits: number = 4) => {
  try {
    if (isNaN(+num)) return num;

    return (+num).toLocaleString(undefined, { maximumFractionDigits });
  } catch (err) {
    return num;
  }
};
