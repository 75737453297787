import { reactive } from 'vue';
import { ApplyFormStatusEnum } from '@/view-models/auth.vm';

import useProjectStorage from './useProjectStorage';

const { projectUtilsStorage } = useProjectStorage();

export default () => {
  const promotionForm = reactive({
    applyForm: {
      id: '',
      reMemberId: '',
      callingCode: '',
      mobile: '',
      gender: null,
      // dateBirth: null,
      dateBirth: '',
      name: '',
      email: '',
      city: '',
      region: '',
      address: '',
      promoteCode: '',
      inviterPromoteCode: '',
      status: ApplyFormStatusEnum.draft,
      memo: '',
      userId: '',
      dateCreated: '',
      dateUpdated: '',
      smsOtpId: '',
      isMobileVerified: false,
      user: null,
      inviterPromoteReMember: null,
      certificateImageUrl: '',
      isAccepted: false,
      dateAccepted: '',
      googleUserId: '',
      reMember: null,
      ableToUpdateInviterPromotionCode: false,
      denyReason: '',
    },
  });

  const generateKOLForm = () => ({
    applyForm: {
      smsOtpId: projectUtilsStorage.value.otpId || '',

      // dto
      reMemberId: '',
      name: '',
      email: '',
      city: '',
      region: '',
      address: '',
      googleUserId: '',
      callingCode: projectUtilsStorage.value.callingCode || '886', // default value
      mobile: projectUtilsStorage.value.mobile || '',
      dateBirth: '',
      gender: 'male',
      extra: {
        promoteCode: '',
      },
    },
  });

  const KOLForm = reactive(generateKOLForm());

  return { promotionForm, KOLForm, generateKOLForm };
};
