import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { RouteRecord } from './record';
import { RouteRecordKOL } from './record-kol';

// use redirect
// import NotFind from '@/views/NotFind.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    // redirect: { name: RouteRecord.LandingPage.name },
    redirect: { name: RouteRecordKOL.LandingPage.name },
  },
  {
    path: '/',
    redirect: { name: RouteRecordKOL.LandingPage.name },
  },
  RouteRecord.LandingPage,
  RouteRecord.Home,
  RouteRecord.Login,
  {
    ...RouteRecord.SignUp,
    redirect: { name: RouteRecord.SignUpSms.name },
    children: [RouteRecord.SignUpForm, RouteRecord.SignUpSms, RouteRecord.SignUpDone],
  },
  RouteRecord.Recommend,
  RouteRecord.Feedback,
  RouteRecord.Download,
  RouteRecord.DownloadDetails,
  RouteRecord.DailyBonus,
  RouteRecord.Reward,

  // // KOL path
  // RouteRecordKOL.LandingPage,
  // RouteRecordKOL.Login,
  // {
  //   ...RouteRecordKOL.SignUp,
  //   // redirect: { name: RouteRecordKOL.SignUpSms.name },
  //   children: [RouteRecordKOL.SignUpForm, RouteRecordKOL.SignUpSms, RouteRecordKOL.SignUpDone],
  // },
];

export const KOLRoutes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(KOL/.*)*',
    redirect: { name: RouteRecordKOL.LandingPageRedirect.name },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: RouteRecordKOL.LandingPageRedirect.name },
  },

  RouteRecordKOL.Home,
  RouteRecordKOL.Recommend,
  RouteRecordKOL.Feedback,
  RouteRecordKOL.User,

  RouteRecordKOL.LandingPageRedirect,
  RouteRecordKOL.LandingPage,
  RouteRecordKOL.Login,
  {
    ...RouteRecordKOL.SignUp,
    redirect: { name: RouteRecordKOL.SignUpSms.name },
    children: [RouteRecordKOL.SignUpForm, RouteRecordKOL.SignUpSms, RouteRecordKOL.SignUpDone],
  },
];

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // remove delivery project route, not show in front end
  // routes: [...routes, ...KOLRoutes],
  routes: [...KOLRoutes],
});

export { RouteRecord, RouteRecordKOL };
