import { App } from 'vue';
import {
  Locale,
  Button,
  Image,
  NavBar,
  Sidebar,
  SidebarItem,
  Empty,
  Icon,
  Form,
  Field,
  Calendar,
  Cell,
  Tag,
  CellGroup,
  Checkbox,
  DatetimePicker,
  Popup,
  Uploader,
  DropdownItem,
  DropdownMenu,
  Picker,
  Pagination,
  Grid,
  GridItem,
  Row,
  Col,
  Loading,
  Toast,
} from 'vant';
import zhTW from 'vant/es/locale/lang/zh-TW';
import zhCN from 'vant/es/locale/lang/zh-CN';
import enUS from 'vant/es/locale/lang/en-US';
import '@vant/touch-emulator';
import 'vant/lib/index.css';

Locale.add({
  'zh-TW': zhTW,
  'zh-CN': zhCN,
  'en-US': enUS,
});

export function installVant(app: App<Element>) {
  /**
   * Toast configuration
   * REF: https://vant-ui.github.io/vant/#/en-US/toast
   */

  const baseToastConfiguration = {
    duration: 5000,
  };

  Toast.setDefaultOptions(baseToastConfiguration);

  /**
   * main use vant component
   */
  app
    .use(Button)
    .use(Icon)
    .use(Image)
    .use(NavBar)
    .use(Sidebar)
    .use(SidebarItem)
    .use(Empty)
    .use(Form)
    .use(Field)
    .use(Calendar)
    .use(Cell)
    .use(CellGroup)
    .use(Checkbox)
    .use(DatetimePicker)
    .use(Popup)
    .use(Uploader)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Picker)
    .use(Grid)
    .use(GridItem)
    .use(Row)
    .use(Col)
    .use(Pagination)
    .use(Tag)
    .use(Loading);
}
