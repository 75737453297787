import { ref, watchEffect } from 'vue';
import { useThrottle } from '@vueuse/shared';
import { useWindowSize } from '@vueuse/core';
import 'dotenv/config';
import useMockGoogleLogin from './useMockGoogleLogin';
import { googleIdToken } from './auth';

const { NODE_ENV } = process.env;
let { VUE_APP_NODE_ENV } = process.env;

if (!VUE_APP_NODE_ENV) VUE_APP_NODE_ENV = 'development';

const { width } = useWindowSize();
const { isMockGoogleLogin, mockLogin, mockGoogleIdToken } = useMockGoogleLogin();

const isDev = ref(false);
const isProd = ref(false);
const isMobile = ref(false);

if (NODE_ENV) isDev.value = VUE_APP_NODE_ENV.toLowerCase() === 'development';
if (NODE_ENV) isProd.value = VUE_APP_NODE_ENV.toLowerCase() === 'production';

isMockGoogleLogin.value = googleIdToken.value.slice(0, mockGoogleIdToken.length) === mockGoogleIdToken;
if (isMockGoogleLogin.value) mockLogin();

console.log('Client node env: ', NODE_ENV, '(usually not use this!)');
console.log('Server node env: ', VUE_APP_NODE_ENV, '(depend on server NODE_ENV)');
console.log('isDev: ', isDev.value);
console.log('isProd: ', isProd.value);
console.log('isMockGoogleLogin: ', isMockGoogleLogin.value);

export { isDev, isProd };

export const useUtils = () => {
  watchEffect(() => {
    if (useThrottle(width, 250).value < 768) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
    }
  });
  return {
    isMobile,
  };
};
