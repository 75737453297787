import gql from 'graphql-tag';
import { userFragment } from '../queries/auth';

export const dailyCheckIn = gql`
  mutation dailyCheckIn {
    dailyCheckIn {
      financeRcoinAccountHistoryId
      walletHistoryId
    }
  }
`;

export const userReceiveReward = gql`
  mutation userReceiveReward($userPromoteRcoinRewardId: String!) {
    userReceiveReward(userPromoteRcoinRewardId: $userPromoteRcoinRewardId) {
      id
      userId
      promoteCount
      promoteRcoinAmount
      dateReachedTarget
      dateReceivedStart
      dateReceivedEnd
      receivedCount
      maxReceiveCount
      description
      dateCreated
      dateUpdated
      isAbleToReceive
      receivedHistories {
        id
        userPromoteRcoinRewardId
        financeRcoinAccountHistoryId
        dateCreated
      }
    }
  }
`;
export const updateUserPromoteCode = gql`
  mutation updateUserPromoteCode($promoteCode: String!) {
    updateUserPromoteCode(promoteCode: $promoteCode) {
      ...user
    }
  }
  ${userFragment}
`;
