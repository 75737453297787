import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export interface PromoteActivitySettingVM {
  currentEnabled: boolean;
  oriVars: {
    dateStart?: string;
    dateEnd?: string;
  };
}

export function useActivitySetting() {
  const { result } = useQuery<{
    rewardRcoinActivitySetting: PromoteActivitySettingVM;
    dailyCheckInActivitySetting: PromoteActivitySettingVM;
  }>(
    gql`
      query getActivitySetting {
        rewardRcoinActivitySetting {
          currentEnabled
          oriVars {
            dateStart
            dateEnd
          }
        }
        dailyCheckInActivitySetting {
          currentEnabled
          oriVars {
            dateStart
            dateEnd
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'network-only' },
  );

  // const defaultSetting = {
  //   currentEnabled: false,
  //   oriVars: {
  //     dateStart: null,
  //     dateEnd: null
  //   }
  // }
  const rewardRcoinActivitySetting = computed(() => result.value?.rewardRcoinActivitySetting ?? null);
  const dailyCheckInActivitySetting = computed(() => result.value?.dailyCheckInActivitySetting ?? null);

  return {
    rewardRcoinActivitySetting,
    dailyCheckInActivitySetting,
  };
}
