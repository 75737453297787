import gql from 'graphql-tag';

export const getCities = gql`
  query getCities($countryCode: String) {
    cities(countryCode: $countryCode) {
      name
      list {
        name
        zipCode
      }
    }
  }
`;
