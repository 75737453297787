export enum TokenTypeEnum {
  loginToken = 'loginToken',
  registerToken = 'registerToken',
}

export enum ApplyFormStatusEnum {
  draft = 'draft',
  pending = 'pending',
  accept = 'accept',
  deny = 'deny',
}

export interface ReMemberVM {
  id: string;
  callingCode: string;
  mobile: string;
  name: string;
  nickName: string;
  promotionUser: UserVM;
}

export interface ApplyFormVM {
  id: string;
  reMemberId: string;
  callingCode: string;
  gender: 'male' | 'female' | null;
  mobile: string;
  // dateBirth: string | null;
  dateBirth: string;
  name: string;
  email: string;
  city: string;
  region: string;
  address: string;
  promoteCode: string;
  inviterPromoteCode: string;
  status: ApplyFormStatusEnum;
  memo: string;
  userId: string;
  dateCreated: string;
  dateUpdated: string;
  smsOtpId: string | null;
  isMobileVerified: boolean;
  user: UserVM | null;
  inviterPromoteReMember: ReMemberVM | null;
  certificateImageUrl: string;
  isAccepted: boolean;
  dateAccepted: string;
  googleUserId: string;
  reMember: ReMemberVM | null;
  ableToUpdateInviterPromotionCode: boolean;
  denyReason: string;
}

export interface KOLApplyFormVM {
  isMobileVerified?: boolean;
  isAccepted?: boolean;

  // id?: string;
  reMemberId: string;
  smsOtpId: string;
  name: string;
  email: string;
  city: string;
  region: string;
  address?: string; // no required
  googleUserId: string;
  callingCode: string;
  mobile: string;
  dateBirth: string;
  gender: string;
  extra: {
    promoteCode: string;
  };
}

export interface IdTokenVM {
  token: string;
  type: TokenTypeEnum;
  applyForm: ApplyFormVM & KOLApplyFormVM;
  user: UserVM;
}

export interface UserVM {
  id: string;
  reMemberId: string;
  callingCode: string;
  mobile: string;
  dateBirth: string;
  name: string;
  email: string;
  city: string;
  region: string;
  address: string;
  promoteCode: string;
  inviterPromoteCode: string;
}

interface Project {
  projectId: string;
  dateStart: string;
  dateEnd: string;
  enabled: boolean;
  dateUpdated: string;
  dateCreated: string;
}

export interface KOLUserVM {
  id: string;
  googleUserId: string;
  reMemberId: string | null;
  name: string | null;
  gender: string | null;
  email: string | null;
  callingCode: string | null;
  mobile: string | null;
  city: string | null;
  region: string | null;
  address: string | null;
  dateBirth: string | null;
  promoteCode: string | null;
  projects: Project[];
}
