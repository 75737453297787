import _uniq from 'lodash/uniq';
import { ApolloLink, ApolloClient, InMemoryCache, defaultDataIdFromObject } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { Toast } from 'vant';

import { token } from '@/composable/auth';

const isDev = process.env.NODE_ENV !== 'production';

// Base Link
const baseLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authentication: token.value,
    },
  });

  return forward(operation);
});

// Error Link
const errorLink = onError((error) => {
  const errors = error.response?.errors || error.graphQLErrors;

  if (errors && errors.length) {
    const errorMsg = _uniq(
      errors.filter((e) => (e.path ? e.path.indexOf('storeApplicationForm') < 0 : true)).map((e) => e.message),
    ).join('\n');

    if (errorMsg.length) {
      Toast({
        type: 'fail',
        message: errorMsg,
        duration: 5000,
        position: 'bottom',
      });
    }
  } else if (error.networkError) {
    Toast({
      type: 'fail',
      message: error.networkError.message,
      duration: 5000,
      position: 'bottom',
    });
  }
});

// Upload Link
console.log(process.env.BASE_URL);
const uploadLink = createUploadLink({
  uri: process.env.BASE_URL + 'graphql', // 'http://192.168.11.250:8890/api/graphql',
  credentials: 'same-origin',
});

const httpLink = ApolloLink.from([baseLink, errorLink, uploadLink]);

export const apolloClient = new ApolloClient({
  // uri: process.env.BASE_URL + 'graphql', // 'http://192.168.11.250:8890/api/graphql',
  link: httpLink,
  cache: new InMemoryCache({
    dataIdFromObject(object) {
      switch (object.__typename) {
        // case 'WfFormStep':
        //   return `${object.id}-${(object as any).applyStep?.id ?? ''}`
        default:
          return defaultDataIdFromObject(object);
      }
    },
  }),
  ssrMode: false,
  connectToDevTools: isDev,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
