import gql from 'graphql-tag';

import { pageInfoFragment } from './common';

export const getPromoteStatusList = gql`
  query getPromoteStatusList($page: PageParameter!, $param: GetUserPromoteStatusDashboardParameter!) {
    promoteStatusList(param: $param, page: $page) {
      items {
        id
        inviteeMaskMobile
        inviteeType
        isInviteeFirstConsumed
        isInviteeFirstConsumedEffected
        consumedStatsString
        dateInviteeRegistered
      }
      page {
        ...pageInfo
      }
    }
  }
  ${pageInfoFragment}
`;

export const getPromoteStatusDashboard = gql`
  query getPromoteStatusDashboard($param: GetUserPromoteStatusDashboardParameter!) {
    promoteStatusDashboard(param: $param) {
      consumer {
        consumedCount
        unconsumedCount
      }
      deliveryDriver {
        consumedCount
        unconsumedCount
      }
    }
  }
`;

export const getUserWalletHistories = gql`
  query getUserWalletHistories($page: PageParameter!, $param: GetPromotionUserWalletHistoryParameter!) {
    userWalletHistories(param: $param, page: $page) {
      items {
        promoteUserType
        historyType
        amount
        dateCreated
      }
      page {
        ...pageInfo
      }
    }
  }
  ${pageInfoFragment}
`;

export const getUserWalletDashboard = gql`
  query getUserWalletDashboard($param: GetPromotionUserWalletDashboardParameter!) {
    userWalletDashboard(param: $param) {
      all {
        total
        fromConsumer
        fromDeliveryDriver
      }
      received {
        total
        fromConsumer
        fromDeliveryDriver
      }
      waitingEffected {
        total
        fromConsumer
        fromDeliveryDriver
      }
    }
  }
`;

export const getCheckInRecordStatus = gql`
  query checkInRecordStatus {
    checkInRecordStatus {
      isTodayCheckIn
      ableReceiveRcoin
      receiveRcoin
      items {
        id
        userId
        dateRecord
        continuousDailyCount
        isAbleToReceiveBonus
        walletHistoryId
        dateCreated
        dateUpdated
      }
    }
  }
`;

export const getUserPromoteRcoinRewards = gql`
  query getUserPromoteRcoinRewards {
    userPromoteRcoinRewards {
      id
      userId
      promoteCount
      promoteRcoinAmount
      dateReachedTarget
      dateReceivedStart
      dateReceivedEnd
      receivedCount
      maxReceiveCount
      description
      dateCreated
      dateUpdated
      isAbleToReceive
      isTodayReceived
    }
  }
`;
