import { createApp, h, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';

import '@/assets/css/re-web-font.css';
import '@/assets/css/refonts-boss.css';
import '@/assets/css/tailwind.css';
import '@/assets/postcss/index.postcss';

import App from './App.vue';

import { i18n } from '@/plugins/vue-i18n'
import router from '@/router';
// import { installLIFF } from '@/plugins/liff';
import { installGapiSDK } from '@/plugins/google-api';
import { installDirective } from '@/plugins/directive';
import { installVant } from '@/plugins/vant';
// import { installVueApollo } from '@/plugins/vue-apollo';
import { apolloClient } from '@/apollo/network-interfaces';
import { detectedInAppBrowser } from '@/composable/browser';
import '@/router/guards';

(async () => {
  detectedInAppBrowser();

  await installGapiSDK();

  const app = createApp({
    setup() {
      provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
  });

  installDirective(app);
  installVant(app);
  // installVueApollo(app);

  app.use(router).use(i18n).mount('#app');
})();
