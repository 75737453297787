import { useStorage } from '@vueuse/core';

const TOKEN_KEY = '_repplt';
const KOL_TOKEN_KEY = '_rerpt'; // project token
const KOL_API_TOKEN_KEY = '_reat'; // api access token
const KOL_OTP_ID_KEY = '_rotpi';
const KOL_MT_KEY = '_rmt';

// token stuff
const googleIdToken = useStorage(TOKEN_KEY, ''); // ! util token in delivery and KOL project
const projectRpt = useStorage(KOL_TOKEN_KEY, ''); // for project(KOL) `Referral Program Token` to login
const projectT = useStorage(KOL_API_TOKEN_KEY, ''); // for access token
const projectMt = useStorage(KOL_MT_KEY, ''); // for project(KOL) `Member Token` to register from

const initialUtilsStorage = {
  mobile: '',
  callingCode: '',
  otpId: '',
  mt: '',
  hasShowKOLRules: false,
};
const projectUtilsStorage = useStorage(KOL_OTP_ID_KEY, initialUtilsStorage);

export default () => {
  return {
    projectUtilsStorage,

    googleIdToken,
    projectRpt,
    projectT,
    projectMt,
  };
};
