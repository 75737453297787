import axios from '@/plugins/axios';
import { Toast } from 'vant';
import 'dotenv/config';

import router, { RouteRecordKOL } from '@/router';
import * as KOLMock from './kol.mock'
import { projectT, projectMt, projectRpt, createTokenByGoogleIdWithReApi, isNeedRegisterKOL } from '@/composable/auth';
import { isMock } from '@/composable/services/useKolSvc'

import type { BaseResponse, PageResponse } from './dto/base.dto';
// service request type
import type { PostSmsOtpDto, PutSmsOtpDto, PostGoogleLoginDto, PostFormDto } from './dto/kol.dto';
// service response type
import type {
  ProjectItem,
  GoogleLoginItem,
  FormItem,
  UserItem,
  PostSmsOtpItem,
  PutSmsOtpItem,
  UserProjectItem,
  UserPromotionItem,
  UserRewardBindingItem,
  UserRewardItem,
  UserRewardDashboardItem,
} from './dto/kol.dto';

/**
 * ref: https://satin-chanter-fb7.notion.site/7ebcc004454f46a3b85ec833bff0e0ca?v=db926ffa31964de0b3361b1c5420652c
 *
 * /v2/referral-program/auth/login/google
 * /v2/referral-program/user/information
 * /v2/referral-program/user/reward
 * /v2/referral-program/user/reward/binding
 * /v2/referral-program/user/promotion
 * /v2/referral-program/user/project
 * /v2/referral-program/project/:projectId/pre-verification
 * /v2/referral-program/project/:projectId/apply
 * /v2/referral-program/project
 */

// const baseUrl = 'http://192.168.11.250:30030';

const { BASE_URL, PUBLIC_PATH } = process.env
const publicPath = PUBLIC_PATH || '/';

const baseUrl = `${BASE_URL || '/'}api`;
const KOLPath = '/v2/referral-program';
const KOLUrl = `${baseUrl}${KOLPath}`;

console.log('==== KOL Api Path =======')
console.log(PUBLIC_PATH, publicPath, KOLUrl)
console.log('=========================')

axios.interceptors.response.use(
  (response) => {
    const { data } =  response
    if (data.t) {
      projectT.value = data.t
    }

    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.resultCode) {
        case '403.402':
        case '403.17':
          Toast({ type: 'fail', message: '登入過期或您已從別的裝置登入！'})
          router.push({ name: RouteRecordKOL.Login.name });
          return;
        default:
          console.error(error.message)
      }
    }
  }
)

const defaultPage = {
  index: 1,
  size: 10,
  amount: 0,
}

const errorHandler = (err) => {
  console.error('[Service Error]: ', err);
  throw new Error(err)
};

const getProjectRpt = async () => {
  if (!projectRpt.value) {
    const bool = await createTokenByGoogleIdWithReApi();
    if (!bool) {
      Toast({ type: 'fail', message: '請先登入！', duration: 3000 })
      router.push({ name: RouteRecordKOL.Login.name });
      // console.error('code: not_login');
      return projectRpt.value;
    }
  } else {
    return projectRpt.value;
  }
}

// ---
// ✅ finish = Api check
// ---

// ✅ finish, has mock
export const getUser = async () => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUser
  return axios
    .get<BaseResponse<UserItem>>(`${KOLUrl}/user/information`, { headers: { t, rpt }})
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish, has mock
export const getUserReward = async (page = defaultPage, dateStart = '', dateEnd = '') => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUserReward
  return axios
    .get<PageResponse<UserRewardItem>>(`${KOLUrl}/user/reward`, {
      headers: { t, rpt },
      params: {
        'date-start': dateStart,
        'date-end': dateEnd,
        p: page.index,
        ps: page.size,
      }
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish, has mock
export const getUserRewardDashboard = async () => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUserRewardDashboard
  return axios
    .get<BaseResponse<UserRewardDashboardItem>>(`${KOLUrl}/user/reward/dashboard`, {
      headers: { t, rpt },
    })
    .then((res) => res)
    .catch(errorHandler);
};


// ✅ finish, has mock
export const getUserRewardBinding = async (page = defaultPage) => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUserRewardBinding
  return axios
    .get<PageResponse<UserRewardBindingItem>>(`${KOLUrl}/user/reward/binding`, {
      headers: { t, rpt },
      params: {
        p: page.index,
        ps: page.size,
      }
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish, has mock
export const getUserPromotion = async (page = defaultPage) => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUserPromotion
  return axios
    .get<PageResponse<UserPromotionItem>>(`${KOLUrl}/user/promotion`, {
      headers: { t, rpt },
      params: {
        p: page.index,
        ps: page.size,
      }
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish, has mock
export const getUserProject = async (page = defaultPage) => {
  const t = projectT.value
  const rpt = await getProjectRpt()
  if (isMock.value) return KOLMock.getUserProject
  return axios
    .get<PageResponse<UserProjectItem>>(`${KOLUrl}/user/project`, {
      headers: { t, rpt },
      params: {
        p: page.index,
        ps: page.size,
      }
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish
export const getProject = (page = defaultPage) => {
  const t = projectT.value
  return axios
    .get<PageResponse<ProjectItem>>(`${KOLUrl}/project`, {
      headers: { t },
      params: {
        p: page.index,
        ps: page.size,
      }
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish
export const postPreVerification = (projectId: string, dto: PostSmsOtpDto) => {
  const t = projectT.value
  return axios
    .post<BaseResponse<PostSmsOtpItem>>(`${KOLUrl}/project/${projectId}/pre-verification`, dto, { headers: { t }})
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish
export const putPreVerification = (projectId: string, dto: PutSmsOtpDto) => {
  const t = projectT.value
  return axios
    .put<BaseResponse<PutSmsOtpItem>>(`${KOLUrl}/project/${projectId}/pre-verification`, dto, { headers: { t }})
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish
export const postForm = (projectId: string, dto: PostFormDto) => {
  const t = projectT.value
  if (!projectMt.value) {
    Toast({ type: 'fail', message: '請先驗證簡訊 OTP ！'});
    return;
  }
  const mt = projectMt.value

  // console.log('mt', mt)

  return axios
    .post<PageResponse<FormItem>>(`${KOLUrl}/project/${projectId}/apply`, dto, {
      headers: { t, mt },
    })
    .then((res) => res)
    .catch(errorHandler);
};

// ✅ finish
export const postGoogleLogin = (dto: PostGoogleLoginDto) => {
  const t = projectT.value
  return axios
    .post<BaseResponse<GoogleLoginItem>>(`${KOLUrl}/auth/login/google`, dto, { headers: { t }})
    .then((res) => res)
    .catch(errorHandler);
};


export const getIpLocation = () => {
  return axios.get<{ success: boolean, location: { ipLocation: string, availableAreas: { code: string, callingCode: string, name: string }[] } }>(`/api/official/service/ip-location`, {
    headers: {
      t: projectT.value
    }
  }).then(res => res)
  .catch(errorHandler)
}
