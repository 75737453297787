import { useTitle } from '@vueuse/core';
import { isNavigationFailure } from 'vue-router';

import router from '../index';
import deliveryBeforeEach from './delivery.before-each';
import kolBeforeEach from './kol.before-each';
import { usePageParameter } from '@/composable/usePageParameter';
import { i18n } from '@/plugins/vue-i18n';

const { isKOLPage, updatePathname } = usePageParameter();

const title = useTitle();

router.beforeEach(async (to, from, next) => {
  // console.log('isKOLPage:', isKOLPage.value);
  // console.log('isKOLLoggedIn', isKOLLoggedIn.value);
  // console.log('isNeedRegisterKOL:', isNeedRegisterKOL.value);
  // debugger;
  if (isKOLPage.value) {
    await kolBeforeEach(to, from, next);
  } else {
    await deliveryBeforeEach(to, from, next);
  }
});

router.afterEach((to, from, failure) => {
  if (to.name === from.name) return;
  updatePathname();
  if (isNavigationFailure(failure)) {
    console.log('failed navigation', failure);
  }
  title.value = `RE·X ${i18n.global.t('kol_platform_title')} ${to.meta?.label ? `- ${to.meta?.label}` : ''}`;

  window.scrollTo(0, 0);
});

router.onError((err) => {
  console.error('Router Error', err);
  const pattern = /Loading chunk (.*)+ failed/g;
  if (pattern.test(err.message)) {
    // @ts-ignore
    const targetPath = router?.history?.pending?.fullPath;
    if (targetPath) {
      router.replace(targetPath);
    }
    window.location.reload();
  }
});
