import UaParser from 'ua-parser-js';
import { v1 as uuidv1 } from 'uuid';
import { typeChecker } from '@re/utilities'

const uaParser = new UaParser();

// id	String
// DeviceId

// os	Number
// 作業系統 1.iOS, 2.android, 3.windows ,4.osx ,5.Linux

// clientName	Number
// 載具名稱 1.re 2.re_boss 3.chrome 4.firefox 5.safari 6.otherWebBrowser

// clientType	Number
// 載具類別 1. app, 2. web

// osVersion	String
// os版本

// clientVersion	String
// 載具版本

// reClientVersion	String
// 軟體版本

function getOSNum(name) {
  let n = -1;
  switch (name) {
    case 'iOS':
      n = 1;
      break;
    case 'Android':
      n = 2;
      break;
    case 'Windows':
      n = 3;
      break;
    case 'Mac OS':
      n = 4;
      break;
    //case 'Linux': n = 5; break;
    default:
      n = 5;
  }
  return n;
}

function getBrowserNum(name) {
  name = name ? name.toLowerCase() : '';
  let n = -1;
  switch (name) {
    case 'chrome':
      n = 3;
      break;
    case 'firefox':
      n = 4;
      break;
    case 'safari':
      n = 5;
      break;
    default:
      n = 6;
  }
  return n;
}

export default () => {
  let d: any = {};
  let os = uaParser.getOS();

  // @ts-ignore
  if (window && window.__isInReApp) {
    // @ts-ignore
    let bridge = window.__myreAppBridge;

    d.id = bridge.getDeviceID();
    d.os = getOSNum(os.name);
    d.clientName = 2; // 2 = re_boss
    d.clientType = 1; // 1 = app 2 = web
    d.osVersion = bridge.getClient() || '0';
    d.clientVersion = bridge.getClient() || '0';
    d.reClientVersion = bridge.getAppVersion() || '0';
  } else {
    let browser = uaParser.getBrowser();
    // d.id = jsCookie.get('d') || null;
    d.id = localStorage.getItem('_redid')
    d.os = getOSNum(os.name);
    d.clientName = getBrowserNum(browser.name); // 2=re_boss
    d.clientType = 2; // 1=app 2=web
    d.osVersion = os.version || '0';
    d.clientVersion = browser.major || '0';
    d.reClientVersion = browser.version || '0';
  }

  if (!typeChecker.isUUID(d.id)) {
    d.id = uuidv1()
    localStorage.setItem('_redid', d.id)
  }

  // console.log(`ua data: ${JSON.stringify(d)}`);

  return d;
};
