import { RouteRecord, RouteRecordKOL } from '../index';
import useAuth, { isLoggedIn, createTokenByGoogleIdToken, token, isRegisterToken } from '@/composable/auth';

const { getProfile } = useAuth();

export default async (to, from, next) => {
  if (to.name === RouteRecord.LandingPage.name) {
    return next();
  }
  if (to.name === RouteRecordKOL.LandingPage.name) {
    return next();
  }
  if (!token.value) {
    await createTokenByGoogleIdToken();
  }
  await getProfile();
  if (to.name === from.name) {
    return next(false);
  }
  if (!to.meta.auth) {
    return next();
  }
  if (isRegisterToken.value) {
    return next({ name: RouteRecord.SignUp.name });
  }
  if (!isLoggedIn.value) {
    return next({ name: RouteRecord.Login.name });
  }
  next();
};
