export enum PromoteUserTypeEnum {
  consumer = 'consumer',
  deliveryDriver = 'deliveryDriver',
}

export const PromoteUserTypeMapping = {
  [PromoteUserTypeEnum.consumer]: '消費者',
  [PromoteUserTypeEnum.deliveryDriver]: '外送員',
};

export enum WalletTypeEnum {
  all = 'all',
  waitingEffected = 'waitingEffected',
  received = 'received',
}

export enum HistoryTypeEnum {
  /**
   * 每日簽到獎勵
   */
  dailyCheckIn = 'dailyCheckIn',
  /**
   * 加入推廣平台獎勵
   */
  earlyBirdReward = 'earlyBirdReward',
  /**
   * 推薦消費者首次消費獎勵
   */
  promoteDeliveryDriverFirstConsumptionReward = 'promoteDeliveryDriverFirstConsumptionReward',
  /**
   * 推薦推廣人員首次消費獎勵
   */
  promoteConsumerUserFirstConsumptionReward = 'promoteConsumerUserFirstConsumptionReward',
  /**
   * 使用獎勵
   */
  rewardUsed = 'reward-used',
  /**
   * 推薦人數達標獎勵
   */
  receivePromoteDeliveryDriverDailyReward = 'receivePromoteDeliveryDriverDailyReward',

  /**
   * 消費回饋獎勵
   */
  promoteUserConsumptionRandomReward = 'promoteUserConsumptionRandomReward',
  /**
   * @deprecated 推薦獎勵
   */
  promotion = 'promotion',
  /**
   * @deprecated 推薦人消費獎勵
   */
  consumption = 'consumption',
}

export const HistoryTypeMapping = {
  [HistoryTypeEnum.dailyCheckIn]: '每日簽到獎勵',
  [HistoryTypeEnum.earlyBirdReward]: '加入推廣平台獎勵',
  [HistoryTypeEnum.promoteConsumerUserFirstConsumptionReward]: '推薦消費者首次消費獎勵',
  [HistoryTypeEnum.promoteDeliveryDriverFirstConsumptionReward]: '推薦推廣人員首次消費獎勵',
  [HistoryTypeEnum.promoteUserConsumptionRandomReward]: '消費回饋獎勵',
  [HistoryTypeEnum.receivePromoteDeliveryDriverDailyReward]: '推薦人數達標獎勵',
  [HistoryTypeEnum.rewardUsed]: '使用獎勵',
  [HistoryTypeEnum.promotion]: '推薦獎勵',
  [HistoryTypeEnum.consumption]: '推薦人消費獎勵',
};

export interface CheckInRecordsStatusVM {
  isTodayCheckIn: boolean;
  ableReceiveRcoin: boolean;
  receiveRcoin: number;
  items: {
    id: string;
    userId: string;
    dateRecord: string;
    continuousDailyCount: number;
    isAbleToReceiveBonus: boolean;
    walletHistoryId: string;
    dateCreated: string;
    dateUpdated: string;
  }[];
}

export interface UserPromoteRcoinRewardVM {
  id: string;
  userId: string;
  promoteCount: number;
  promoteRcoinAmount: number;
  dateReachedTarget: string;
  dateReceivedStart: string;
  dateReceivedEnd: string;
  receivedCount: number;
  maxReceiveCount: number;
  description: string;
  dateCreated: string;
  dateUpdated: string;
  isAbleToReceive: boolean;
  isTodayReceived: boolean;
  // receivedHistories: {
  //   id: string;
  //   userPromoteRcoinRewardId: string;
  //   financeRcoinAccountHistoryId: string;
  //   dateCreated: string;
  // }[]
}

export const fileTypes = ['consumer', 'boss', 'deliveryDriver'] as const;
export type FileType = typeof fileTypes[number];
