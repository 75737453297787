import { createI18n } from 'vue-i18n'
import { Locale } from 'vant';

import zhTW from '@/locales/zh-TW.json';
import zhCN from '@/locales/zh-CN.json';
import zhEN from '@/locales/en-US.json';

export const LOCALE_STORAGE_KEY = '_promotion-platform/lang'

export const LOCALE_KEY = {
  enUS: 'en-US',
  zhTW: 'zh-TW',
  zhCN: 'zh-CN',
}

export const locales = [
  {
    key: LOCALE_KEY.zhTW,
    name: '繁體中文',
  },
  {
    key: LOCALE_KEY.zhCN,
    name: '简体中文',
  },
  {
    key: LOCALE_KEY.enUS,
    name: 'English',
  },
]

function getAvailableLocaleCode(languageCode = '') {
  const locale = locales.find(locale => {
    const lowercaseLocale = locale.key.toLowerCase()
    const lowercaseLanguageCode = languageCode.toLowerCase()

    /** ex: zh-tw 或是 zh_tw 都可以 */
    return lowercaseLocale === lowercaseLanguageCode || lowercaseLocale.replace('-', '_') === lowercaseLanguageCode
  })
  if (locale) {
    return locale.key
  }

  /** zh zh-Hant-TW 之類 */
  if (/^zh/.test(languageCode.toLowerCase())) {
    return LOCALE_KEY.zhTW
  }

  return LOCALE_KEY.enUS
}

export function getDeviceLocale() {
  /**
   * Note that in Safari on iOS prior to 10.2, the country code returned is lowercase: "en-us", "fr-fr" etc.
   * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
   */
  const navigatorLanguage = navigator.language || navigator['browserLanguage']

  return getAvailableLocaleCode(navigatorLanguage)
}


function getDefaultLocale() {
  return localStorage.getItem(LOCALE_STORAGE_KEY) || getDeviceLocale()
}


export const i18n = createI18n({
  legacy: false,
  locale: getDefaultLocale(),
  fallbackLocale: LOCALE_KEY.zhTW,
  messages: {
    'zh-TW': zhTW,
    'zh-CN': zhCN,
    'en-US': zhEN,
  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  fallbackWarn: false,
  missingWarn: false,
})

// const loadedLanguages = ["zh-TW", 'zh-CN']; // our default language that is preloaded

export function setI18nLanguage(lang = getDefaultLocale()) {
  // @ts-expect-error zh-TW is string
  i18n.global.locale.value = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  window.localStorage.setItem(LOCALE_STORAGE_KEY, lang)
  Locale.use(lang)
  return lang;
}

setI18nLanguage()


