import { RouteRecordKOL } from '../index';
import useAuth, {
  isKOLLoggedIn,
  createTokenByGoogleIdWithReApi,
  projectRpt,
  isNeedRegisterKOL,
} from '@/composable/auth';

const { getProfile } = useAuth();

export default async (to, from, next) => {
  if (to.name === RouteRecordKOL.LandingPage.name) {
    return next();
  }
  if (!projectRpt.value) {
    await createTokenByGoogleIdWithReApi();
  }
  await getProfile();
  if (to.name === from.name) {
    return next(false);
  }
  if (!to.meta.auth) {
    return next();
  }
  // console.warn('redirect');
  // debugger;
  if (isNeedRegisterKOL.value) {
    return next({ name: RouteRecordKOL.SignUp.name });
  }
  if (!isKOLLoggedIn.value) {
    return next({ name: RouteRecordKOL.Login.name });
  }
  next();
};
